import React from "react"
import { Control, useFormContext, useWatch } from "react-hook-form"
import { useProcessActions } from "~/hooks/useProcessActions"
import { useUpdateDebounce } from "~/hooks/useUpdateDebounce"
import { interactionResponse } from "~/services/webvitals/defer"

interface Props {
  control: Control<{ [key: string]: string }>
  onChange: (data: any) => void
  fields: any[]
}

const PaymentFormWatcher: React.FC<Props> = ({ control, onChange, fields }) => {
  const process = useProcessActions()

  const watchFields = useWatch({
    control,
    name: fields,
  })

  const {
    formState: { isDirty },
  } = useFormContext()

  useUpdateDebounce(
    () => {
      const run = async () => {
        await interactionResponse()

        const data: any = {}
        fields.forEach((name, index) => {
          data[name] = watchFields[index]
        })

        if (isDirty) {
          process.fireAnalyticEvent({ eventType: "beginCheckout", data: {} })
        }

        onChange(data)
      }
      run()
    },
    200,
    [JSON.stringify(watchFields)]
  )

  return null
}

export default PaymentFormWatcher
